var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.id || !_vm.displayedSplit || _vm.loadingSplit
        ? _c("v-skeleton-loader", { attrs: { type: "text@3" } })
        : _c(
            "div",
            { staticClass: "pb-4" },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("From: " + _vm._s(_vm.displayedSplit.fromName) + " ")
              ]),
              _c("span", { staticClass: "text-caption" }, [
                _vm._v(
                  "(" +
                    _vm._s(_vm.displayedSplit.fromAddress) +
                    "@" +
                    _vm._s(
                      _vm.displayedSplit.replyAddress || "mminfo2.messages4.com"
                    ) +
                    ")"
                )
              ]),
              _c("v-divider"),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("Subject: " + _vm._s(_vm.displayedSplit.subject) + " ")
              ]),
              _vm.displayedSplit.previewText ? _c("v-divider") : _vm._e(),
              _vm.displayedSplit.previewText
                ? _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      "Preview Text: " + _vm._s(_vm.displayedSplit.previewText)
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
      !_vm.id || !_vm.displayedSplit || _vm.loading || !_vm.displayedSplit.html
        ? _c("v-skeleton-loader", { attrs: { type: "article@5" } })
        : _c("SplitDevicePreview", {
            attrs: {
              "split-id": _vm.displayedSplit.id,
              html: _vm.displayedSplit.html,
              "text-accessibility": _vm.displayedSplit.textAccessibility,
              errors: _vm.errors,
              warnings: _vm.warnings
            },
            on: {
              change: function(value) {
                return _vm.$emit("change", value)
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }