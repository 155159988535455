<template>
  <div>
    <v-skeleton-loader
      v-if="!id || !displayedSplit || loadingSplit"
      type="text@3"
    />
    <div
      v-else
      class="pb-4"
    >
      <span class="font-weight-bold">From: {{ displayedSplit.fromName }} </span><span class="text-caption">({{ displayedSplit.fromAddress }}@{{ displayedSplit.replyAddress || 'mminfo2.messages4.com' }})</span>
      <v-divider />
      <span class="font-weight-bold">Subject: {{ displayedSplit.subject }} </span>
      <v-divider v-if="displayedSplit.previewText" />
      <span
        v-if="displayedSplit.previewText"
        class="font-weight-bold"
      >Preview Text: {{ displayedSplit.previewText }}</span>
    </div>
    <v-skeleton-loader
      v-if="!id || !displayedSplit || loading || !displayedSplit.html"
      type="article@5"
    />
    <SplitDevicePreview
      v-else
      :split-id="displayedSplit.id"
      :html="displayedSplit.html"
      :text-accessibility="displayedSplit.textAccessibility"
      :errors="errors"
      :warnings="warnings"
      @change="value => $emit('change', value)"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { azBlobService, mailingService } from '@/shared/services'

export default {
  name: 'SplitPreview',

  components: {
    SplitDevicePreview: () => import('@/views/pages/components/mailing/SplitDevicePreview')
  },

  props: {
    id: {
      type: String,
      default: null,
      required: true
    },
    split: {
      type: Object,
      default: null,
      required: false
    }
  },

  data () {
    return {
      loadingSplit: false,
      loadingHtml: false,
      mySplit: null,
      errors: [],
      warnings: []
    }
  },

  computed: {
    displayedSplit () {
      return this.split || this.mySplit
    },
    loading () {
      return this.loadingSplit || this.loadingHtml
    }
  },

  watch: {
    id: {
      immediate: true,
      handler (newValue, oldValue) {
        this.errors = []
        this.warnings = []
        this.loadSplit()
      }
    }
  },

  methods: {
    loadSplit () {
      if (this.split) return
      if (!this.id) {
        this.mySplit = null
        return
      }
      this.loadingSplit = true
      mailingService.getSplit(this.id, true).then(resp => {
        this.mySplit = resp
        Vue.set(this.mySplit, 'html', '')
        this.loadingSplit = false
        this.loadingHtml = true
        azBlobService.getSplitHtmlPreview(this.id, true)
          .then(resp2 => {
            if (resp2.errorResponse) {
              this.$store.commit('setGlobalSnackbar', {
                message: resp2.errorResponse,
                color: 'error'
              })
              Vue.set(this.mySplit, 'html', '<p>Creative could not be previewed</p>')
            } else {
              mailingService.validateHtml(this.id, true)
                .then(resp3 => {
                  if (resp3.errorResponse) {
                    this.$store.commit('setGlobalSnackbar', {
                      message: resp.errorResponse,
                      color: 'error'
                    })
                  } else {
                    if (!resp3.isValid) {
                      this.errors = resp3.errors
                    }
                    if (resp3.warnings.length > 0) {
                      this.warnings = resp3.warnings
                    }
                    Vue.set(this.mySplit, 'html', resp2)
                  }
                })
            }
          }).finally(() => {
            this.loadingHtml = false
          })
      }).finally(() => {
        this.loadingSplit = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
